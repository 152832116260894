import { monitorAPI } from '@api';
import { ErrorContent } from '@interfaces';
import { LogApp } from '@utils';
import moment from 'moment';
import React, { ErrorInfo, ReactNode } from 'react';
import { connect } from 'react-redux';
interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
  userInfo?: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether there is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }
  logError = async (error: string) => {
    try {
      const payload: ErrorContent = {
        user_id: this.props.userInfo?.userId ?? '',
        username: this.props.userInfo?.username ?? '',
        detail: error,
        current_url: window.location.href,
        http_status: '',
        created_at: moment().valueOf(),
      };
      const res = await monitorAPI.postErrors(payload);
    } catch (error) {}
  };
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    if (process.env.NODE_ENV === 'production') this.logError(error.message);
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;
    // Check if an error is thrown
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="errorBoundaryWrapper">
          {fallback && <React.Fragment>{fallback}</React.Fragment>}
          {!fallback && <h2>Oops, there is an error!</h2>}
        </div>
      );
    }
    // Return children components in case of no error
    return children;
  }
}
const mapStateToProps = (state: any) => {
  return {
    userInfo: state.auth,
  };
};
export default connect(mapStateToProps)(ErrorBoundary);
