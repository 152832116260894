import { MAIN_THEME_DATA, enumNavKey } from '@configs';
import { ICon } from '@interfaces';
import { selectLayout, useAppSelector } from '@redux';
import { useTheme } from '@theme';
import { LogApp } from '@utils';
import styled from 'styled-components';
import {
  AnnouncementMenuIcon,
  BirthdaySpecialMenuIcon,
  BranchGroupIcon,
  CashbackRuleMenuIcon,
  DashboardMenuIcon,
  GroupMenuIcon,
  CategoriesMenuIcon,
  ProductMenuIcon,
  MemberMenuIcon,
  MemberTierMenuIcon,
  Store,
  WarehouseMenuIcon,
  OrderIcon,
  ProfileIcon,
  BannerIcon,
  XCloseIcon,
} from '../Icon';

interface IProps extends ICon {
  pathKey: enumNavKey;
}

export const NavMenuIcon = (props: IProps) => {
  const { pathKey } = props;

  const { navCurrentKey } = useAppSelector(selectLayout);

  const { theme } = useTheme();
  const genIcon = () => {
    switch (pathKey) {
      case enumNavKey.DASHBOARD:
        return (
          <DashboardMenuIcon
            color={navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : undefined}
            {...props}
          />
        );
      case enumNavKey.CATEGORIES:
        return (
          <CategoriesMenuIcon
            color={navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : undefined}
            {...props}
          />
        );
      case enumNavKey.PRODUCT:
        return (
          <ProductMenuIcon
            color={navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : undefined}
            {...props}
          />
        );

      case enumNavKey.SELLERS:
        return (
          <MemberMenuIcon
            color={navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : undefined}
            {...props}
          />
        );
      case enumNavKey.CUSTOMERS:
        return (
          <ProfileIcon
            color={
              navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : MAIN_THEME_DATA.mainColor
            }
            {...props}
          />
        );
      case enumNavKey.WAREHOUSE:
        return (
          <WarehouseMenuIcon
            color={navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : undefined}
            {...props}
          />
        );
      case enumNavKey.ORDER:
        return (
          <OrderIcon
            color={navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : undefined}
            {...props}
          />
        );
      case enumNavKey.PROMOTION:
        return (
          <AnnouncementMenuIcon
            color={navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : undefined}
            {...props}
          />
        );
      case enumNavKey.BANNERS:
        return (
          <BannerIcon
            color={navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : undefined}
            {...props}
          />
        );
      case enumNavKey.ERRORS:
        return (
          <XCloseIcon
            color={navCurrentKey === pathKey ? theme?.colors?.sidebar?.active : undefined}
            {...props}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{genIcon()}</>;
};

const StyledNavMenuIcon = styled.div<{
  $appTheme?: string;
}>``;
