import { Pagination, SearchIcon, SharedTable, ShareInput } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { ErrorColumn, ErrorData, SubCategoryColumn } from '@interfaces';
import { useTheme } from '@theme';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
interface IProps {
  errors: ErrorData;
  columns: ColumnsType<ErrorColumn>;
  onSearch: (value: string) => void;
  currentPage?: number;
  onPageChange: (value: number) => void;
}

export const ErrorTable = ({ errors, columns, onSearch, currentPage, onPageChange }: IProps) => {
  const { theme } = useTheme();
  return (
    <StyledTableWrapper>
      <StyledSearchWrapper>
        <div className="input-wrapper">
          <ShareInput
            containerClassName="search-tier__box"
            className="search-tier__input"
            onChange={(e: any) => {
              onSearch(e.target.value);
            }}
            placeholder="Tìm kiếm"
            prevIcon={<SearchIcon />}
          />
        </div>
      </StyledSearchWrapper>
      <SharedTable columns={columns} dataSource={errors.data} />
      <div className="bottom-pagination">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={errors.count}
          pageSize={errors.limit}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
    </StyledTableWrapper>
  );
};
const StyledTableWrapper = styled.div`
  .sub-category-block {
    margin-top: 1rem;
    max-height: 30rem;
    overflow-y: auto;
    .title {
      display: inline-block;
      margin-bottom: 0.8rem;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 20px;
      color: #303030;
    }
    .input-wrapper {
      margin-bottom: 1rem;
    }
  }
  .add-sub-category-btn {
    cursor: pointer;
    height: 4rem;
    border: 1px dashed ${MAIN_THEME_DATA.mainColor};
    width: 100%;
    border-radius: 0.6rem;
    margin-top: 1.5rem;
    .text-block {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      span {
        font-weight: bold;
        margin-left: 1rem;
        font-size: larger;
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
  }
  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }
  .level {
    display: flex;
    align-items: center;
    justify-content: center;
    .level-change {
      /* margin-right: 3rem; */
      .btn {
        box-shadow: none;
      }
    }
  }
  .modal {
    width: 100rem;
    .delete {
      position: absolute;
      top: -15px;
      right: -15px;
    }
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
  }
`;
const StyledSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
  .search-tier__box {
    min-width: 20rem;
    width: fit-content;
    .search-tier__input {
      height: 4.5rem;
    }
  }
  .create-tier__button {
    height: 4.5rem;
    width: fit-content;
  }
  /* .input-wrapper {
      width: 30%;
      margin-right: 2rem;
    }
    .text-button {
      padding: 0.6rem 1.2rem;
    } */
`;

const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
