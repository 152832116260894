import { memo } from 'react';

import { IMAGES } from '@assets';
import { SharedButton, SharedImage } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import styled from 'styled-components';

interface IProps {
  error: string;
}

export const ErrorPage = memo((props: IProps) => {
  const { error } = props;
  return (
    <StyledErrorPage>
      <div>
        <SharedImage containerClassName="not-found-img" src={IMAGES.error} />
        <span className="error">Đã có lỗi xảy. Vui lòng liên hệ admin để được hỗ trợ</span>
        <SharedButton
          typeHtml="button"
          text="Quay về trang chủ"
          backgroundColor={MAIN_THEME_DATA.mainColor}
          btnStyle="pad"
          onClick={() => {
            window.location.href = '/';
          }}
        />
      </div>
    </StyledErrorPage>
  );
});

export const StyledErrorPage = styled.div`
  /* background-color: ${MAIN_THEME_DATA.mainColor}; */
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  .not-found-img {
    width: 50rem;
    aspect-ratio: 1;
  }
  .error {
    display: inline-block;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #000;
    margin-bottom: 3.2rem;
  }
`;
