import {
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';

import {
  PopoverPopup,
  ShareDateSelect,
  SharedAutocomplete,
  SharedButton,
  SharedCheckbox,
  ShareInput,
} from '@components';
import { dateOfBirthFormat, MAIN_THEME_DATA, ROLES } from '@configs';
import {
  AddressItem,
  AutocompleteItem,
  SelectedAddress,
  Seller,
  SellerAutocompleteData,
  WarehouseItem,
} from '@interfaces';
import { themes } from '@theme';
import { Radio } from 'antd';
import moment from 'moment';
interface IProps {
  seller: Seller;
  isEdit?: boolean;
  errors: Partial<FieldErrorsImpl<any>>;
  watch: UseFormWatch<any>;
  onSubmit?: () => void;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  onCancel?: () => void;
  onSelectProvince: (value: string) => void;
  onSelectDistrict: (value: string) => void;
  onSelectWard: (value: string) => void;
  autocompleteData: SellerAutocompleteData;
  selectedAddress?: SelectedAddress;
  addressErrors?: any;
  setRole: (role: string) => void;
  isAdmin: boolean;
  resetPassword: () => void;
}

export const SellerForm = (props: IProps) => {
  const {
    seller,
    errors,
    isEdit,
    onCancel,
    setValue,
    onSubmit,
    register,
    watch,
    onSelectDistrict,
    onSelectProvince,
    onSelectWard,
    autocompleteData,
    selectedAddress,
    addressErrors,
    getValues,
    setRole,
    isAdmin,
    resetPassword,
  } = props;
  const isAccountant = watch('role') === ROLES.ACCOUNTANT;
  return (
    <StyledMemberProfileForm>
      <form onSubmit={onSubmit}>
        <div className="form-section">
          {!isEdit && (
            <Radio.Group
              onChange={(e) => {
                setValue('role', e.target.value);
                setRole(e.target.value);
              }}
              value={watch('role')}
            >
              <SharedCheckbox value={ROLES.SELLER} type="radio" text="Seller" />
              <SharedCheckbox value={ROLES.ACCOUNTANT} type="radio" text="Kế toán" />
            </Radio.Group>
          )}
          <div className="create-rule__form">
            {!isEdit && (
              <div className="form-field">
                <div className="label">
                  <p className="label-text">Tài khoản</p>
                </div>
                <div className="acc-block">
                  <ShareInput
                    containerClassName="mx"
                    required
                    placeholder="Nhập tài khoản"
                    name="username"
                    className="input"
                    errors={errors['username']?.message}
                    register={register}
                  />
                  <ShareInput
                    containerClassName="mx"
                    required
                    haveShowPassIcon
                    placeholder="Nhập mật khẩu"
                    name="password"
                    className="input"
                    type="password"
                    errors={errors['password']?.message}
                    register={register}
                  />
                  <ShareInput
                    required
                    placeholder="Xác nhận mật khẩu"
                    name="confirm_password"
                    haveShowPassIcon
                    className="input"
                    type="password"
                    errors={errors['confirm_password']?.message}
                    register={register}
                  />
                </div>
              </div>
            )}
            {!isEdit && (
              <div className="form-field">
                <div className="label">
                  <p className="label-text">Mã nhân viên</p>
                </div>
                <ShareInput
                  required
                  placeholder="Nhập mã nhân viên"
                  name="code"
                  className="input"
                  errors={errors['code']?.message}
                  register={register}
                />
              </div>
            )}
            <div className="form-field">
              <div className="label">
                <p className="label-text">Họ và tên</p>
              </div>
              <ShareInput
                required
                placeholder="Nhập họ và tên"
                name="full_name"
                className="input"
                errors={errors['full_name']?.message}
                register={register}
              />
            </div>
            <div className="form-field">
              <div className="label">
                <p className="label-text">Số điện thoại</p>
              </div>
              <ShareInput
                required
                placeholder="Nhập số điện thoại"
                name="phone_number"
                className="input"
                errors={errors['phone_number']?.message}
                register={register}
              />
            </div>
            <div className="form-field">
              <div className="label">
                <p className="label-text">Email</p>
              </div>
              <ShareInput
                placeholder="Email"
                name="email"
                type="email"
                className="input"
                errors={errors['email']?.message}
                register={register}
              />
            </div>
            <div className="form-field">
              <div className="label">
                <p className="label-text">Ngày sinh</p>
              </div>
              <div className="w-full">
                <ShareDateSelect
                  placeholder="Chọn ngày sinh"
                  maxDate={moment()}
                  format={dateOfBirthFormat}
                  onChange={(date, dateString) => {
                    setValue('dob', dateString);
                  }}
                  defaultValue={seller?.dob ? moment(seller?.dob, dateOfBirthFormat) : undefined}
                  key={`seller:${seller?.dob || ''}`}
                  showNow={false}
                  errors={errors['dob']?.message}
                />
              </div>
            </div>
            <div className="form-field">
              <div className="label">
                <p className="label-text">Kho</p>
              </div>
              <div className="w-full">
                <SharedAutocomplete
                  onSelect={(value) => {
                    setValue('warehouse_id', value.item.id);
                  }}
                  containerClassName="address-input mx"
                  data={autocompleteData.warehouses}
                  label={''}
                  placeholder="Chọn kho"
                  defaultValue={seller?.warehouse?.name}
                  key={`seller:${seller?.warehouse?.name || ''}`}
                  required
                  filterOption={(inputValue: string, option: AutocompleteItem<WarehouseItem>) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  errors={errors['warehouse_id']?.message}
                />
              </div>
            </div>

            <div className="form-field">
              <div className="label">
                <p className="label-text">Địa chỉ</p>
              </div>
              <div className="address-block">
                <SharedAutocomplete
                  onSelect={(value) => {
                    onSelectProvince(value.item.code);
                  }}
                  containerClassName="address-input mx"
                  data={autocompleteData.provinces}
                  label={''}
                  placeholder="Nhập tỉnh/thành phố"
                  defaultValue={seller?.address?.provinces?.province_name}
                  key={`${seller?.address?.provinces?.province_name}:province`}
                  required
                  filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  errors={errors['province_code']?.message}
                />
                <SharedAutocomplete
                  onSelect={(value) => {
                    onSelectDistrict(value.item.code);
                  }}
                  containerClassName="address-input mx"
                  data={autocompleteData.districts}
                  label={''}
                  placeholder="Nhập quận/huyện"
                  // defaultValue={defaultAddress?.district?.name}
                  // key={`${defaultAddress?.district?.code}:district`}
                  defaultValue={seller?.address?.districts?.district_name}
                  key={`${seller?.address?.districts?.district_name}:district`}
                  required
                  filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  errors={errors['district_code']?.message}
                />
                <SharedAutocomplete
                  onSelect={(value) => {
                    onSelectWard(value.item.code);
                  }}
                  containerClassName="address-input"
                  data={autocompleteData.wards}
                  label={''}
                  placeholder="Nhập phường/xã"
                  defaultValue={seller?.address?.wards?.ward_name}
                  key={`${seller?.address?.wards?.ward_name}:ward`}
                  required
                  errors={errors['ward_code']?.message}
                  filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div>
            </div>
            <div className="form-field">
              <div className="label">
                <p className="label-text">Địa chỉ chi tiết</p>
              </div>
              <ShareInput
                placeholder="Nhập địa chỉ chi tiết"
                name="address_detail"
                className="input"
                errors={errors['address_detail']?.message}
                register={register}
              />
            </div>
          </div>
        </div>
        <div className="bottom-content">
          <div className="form__actions">
            <SharedButton
              typeHtml="button"
              className="btn-save btn-cancel mx"
              backgroundColor="transparent"
              textColor={themes.theme.light.colors.button.text}
              borderColor={themes.theme.light.colors.button.border}
              btnStyle="pad"
              text={'Cancel'}
              onClick={onCancel}
            />
            <SharedButton
              typeHtml="submit"
              text={isEdit ? 'Sửa' : 'Thêm'}
              className="create-rule__button mx"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="pad"
            />
            {isAdmin && isEdit && (
              <PopoverPopup
                content={
                  <StyledConfirmPopup>
                    <p className="text-sm">Xác nhận đặt lại mật khẩu cho seller này?</p>
                  </StyledConfirmPopup>
                }
                isHaveConfirmButton
                onConfirm={() => {
                  resetPassword();
                }}
              >
                <SharedButton
                  typeHtml="button"
                  text={'Đặt lại mật khẩu'}
                  className="create-rule__button"
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  btnStyle="pad"
                />
              </PopoverPopup>
            )}
          </div>
        </div>
      </form>
    </StyledMemberProfileForm>
  );
};
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
const StyledMemberProfileForm = styled.div`
  width: 100%;
  .mx {
    margin-right: 1rem;
  }
  .form-section {
    .ant-radio-group {
      display: flex;
      margin-bottom: 1rem;
    }
    .acc-block {
      width: 100%;
      display: flex;
    }
    .base-info-block {
      display: flex;
      flex-direction: row;
    }
    .address-block {
      display: flex;
      flex-direction: row;
      width: 100%;

      .address-input {
        flex: 1;
      }
      .sub-address-input {
        flex: 1;
      }
    }
    position: relative;
    padding: 2rem 2.8rem;
    border-radius: 0.6rem;
    background: ${(p) => p.theme.colors.bgSection};
    box-shadow: 0px 3px 20px #0000000b;
  }
  .desc {
    display: flex;
    align-items: center;
    margin: 1.2rem 0 3.8rem;
    .desc-icon {
      margin-right: 1rem;
    }
  }
  .create-rule__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    .form-field {
      display: flex;
      flex-direction: column;
      margin-bottom: 2.6rem;
      .label {
        margin-right: 2.3rem;
        .label-text {
          font-size: 1.6rem;
          font-weight: 500;
          margin-bottom: 0.6rem;
          color: ${({ theme }: any) => theme?.colors?.text};
        }
        .label-desc {
          font-size: 1.3rem;
          font-weight: 400;
          color: ${({ theme }: any) => theme?.colors?.secondary};
        }
      }
      @media (min-width: 1280px) {
        flex-direction: row;
        .label {
          width: 40%;
        }
      }
    }
    .shared-input {
      margin-bottom: 0.8rem;
    }
    .inner-input,
    .ant-picker,
    input,
    .ant-select-selector {
      @media (min-width: 768px) {
        height: 4rem;
      }
    }
  }

  .bottom-content {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .form__actions {
      display: flex;
      width: 100%;
      .btn {
        width: 100%;
      }
      @media (min-width: 1280px) {
        display: flex;
        align-items: center;
        max-width: 50rem;
      }

      @media (max-width: 820px) {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0;
        .btn {
          width: 100%;
        }
      }
    }
  }
`;
