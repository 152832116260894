import { DefaultApiParams, categoriesAPI, orderAPI, productAPI, warehouseAPI } from '@api';
import { OrderDetailSection } from '@components';
import {
  DEFAULT_API_PARAMS,
  DEFAULT_CATEGORY_FILTER_ITEM,
  DEFAULT_CLASSIFICATION_PARAMS,
  DEFAULT_ORDER_DETAIL,
  DEFAULT_WAREHOUSE_FILTER_ITEM,
  ORDER_STATUS_TEXT,
} from '@configs';
import {
  CategoryListRoot,
  Classification,
  DraftOrderItem,
  Order,
  OrderDetailRoot,
  OrderItem,
  Product,
  ProductFilterDropdowns,
  ProductFilterResponse,
  ProductFilterRoot,
  ProductResponse,
  ProductRoot,
  SubClassification,
  UpdateProductOrderPayload,
  WarehouseRoot,
} from '@interfaces';
import { selectAppLoading, selectAuth, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { LogApp, convertPriceToFloat, convertPriceToInt, generateRandomId } from '@utils';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';
const MAX_ITEM = 10000;

export const OrderDetailModule = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const appLoading = useAppSelector(selectAppLoading);
  const { warehouse_id } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    // mode: 'onChange',
  });
  const [order, setOrder] = useImmer<Order>(DEFAULT_ORDER_DETAIL);
  const [randomKey, setRandomKey] = useState('');
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useImmer<ProductResponse>({
    products: [],
    amount: 0,
    total_amount_remain: 0,
  });
  const [payload, setPayload] = useState<DefaultApiParams>({ ...DEFAULT_API_PARAMS, page: 0 });
  const [dropdownData, setDropdownData] = useImmer<ProductFilterDropdowns>({
    warehouses: [],
    categories: [],
  });
  const getOrder = async () => {
    try {
      dispatch(setLoading(true));
      const res: OrderDetailRoot = await orderAPI.getOrder(id || '');
      setOrder(res.data);
      await getOrderItemDraft(res.data.order_items);
      const {
        total_money,
        money_paid,
        money_debt,
        code,
        status,
        from_address,
        to_address,
        customer,
        seller,
        total_money_debt,
        note,
        customer_level_name,
        money_addon,
        money_deducted,
        total_money_reward_cumulative,
      } = res.data;
      reset({
        code,
        total_money: Math.round(total_money),
        money_paid: Math.round(money_paid),
        money_debt: Math.round(money_debt),
        money_addon: Math.round(money_addon),
        money_deducted: Math.round(money_deducted),
        status: ORDER_STATUS_TEXT[status],
        from_ward: from_address.wards.ward_name,
        from_district: from_address.districts.district_name,
        from_city: from_address.provinces.province_name,
        to_ward: to_address.wards.ward_name,
        to_district: to_address.districts.district_name,
        to_city: to_address.provinces.province_name,
        customer_address: to_address.detail,
        customer_name: customer.customer_name,
        customer_phone: customer.customer_phone_number,
        seller_name: seller.brand_name,
        seller_phone: seller.phone_number,
        total_money_debt: Math.round(total_money_debt),
        note,
        customer_level_name,
        total_money_reward_cumulative: Math.round(total_money_reward_cumulative),
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getOrderItemDraft = async (orderItem?: OrderItem[]) => {
    try {
      const draftOrderItemPayload: DraftOrderItem[] = (orderItem ?? order.order_items).map(
        (item) => {
          return {
            product_id: item.product_id,
            product_item_id: item.product_item_id,
            promotion_id: item.promotions.at(0)?.id,
            quantity: item.amount,
            note: item.note,
          } as DraftOrderItem;
        },
      );
      const draftOrderItemRes: { data: OrderItem[] } = await orderAPI.getOrderItemDraft({
        order_id: id ?? '',
        order_items: draftOrderItemPayload,
      });
      setOrder((d) => {
        d.order_items = draftOrderItemRes.data.map((item) => {
          return {
            ...item,
            showDetail: false,
          };
        });
      });
    } catch (error) {}
  };
  const onViewDetail = (item: OrderItem) => {
    setOrder((draft) => {
      let foundIndex = -1;
      if (!item.product_item_id)
        foundIndex = draft.order_items.findIndex((val) => val.product_id === item.product_id);
      else
        foundIndex = draft.order_items.findIndex(
          (val) => val.product_item_id === item.product_item_id,
        );
      if (foundIndex !== -1) {
        draft.order_items[foundIndex].showDetail = !draft.order_items[foundIndex].showDetail;
      }
    });
  };
  const getOrderItemDraftV2 = async (payload: DraftOrderItem[]) => {
    try {
      const draftOrderItemRes = await orderAPI.getOrderItemDraft({
        order_id: id ?? '',
        order_items: payload,
      });
      setOrder((d) => {
        d.order_items = draftOrderItemRes.data;
      });
    } catch (error) {}
  };
  const onIncrease = (item: OrderItem) => {
    setOrder((draft) => {
      let foundIndex = -1;
      if (!item.product_item_id)
        foundIndex = draft.order_items.findIndex((val) => val.product_id === item.product_id);
      else
        foundIndex = draft.order_items.findIndex(
          (val) => val.product_item_id === item.product_item_id,
        );
      if (foundIndex !== -1 && draft.order_items[foundIndex].amount < MAX_ITEM) {
        draft.order_items[foundIndex].amount++;
      }
    });
    setRandomKey(generateRandomId());
  };
  const onChangeNote = (item: OrderItem, note: string) => {
    setOrder((draft) => {
      let foundIndex = -1;
      if (!item.product_item_id)
        foundIndex = draft.order_items.findIndex((val) => val.product_id === item.product_id);
      else
        foundIndex = draft.order_items.findIndex(
          (val) => val.product_item_id === item.product_item_id,
        );
      draft.order_items[foundIndex].note = note;
    });
  };
  const onDecrease = (item: OrderItem) => {
    setOrder((draft) => {
      let foundIndex = -1;
      if (!item.product_item_id)
        foundIndex = draft.order_items.findIndex((val) => val.product_id === item.product_id);
      else
        foundIndex = draft.order_items.findIndex(
          (val) => val.product_item_id === item.product_item_id,
        );
      if (draft.order_items[foundIndex].amount > 1 && foundIndex !== -1)
        draft.order_items[foundIndex].amount--;
    });
    setRandomKey(generateRandomId());
  };
  const onChangeKey = debounce((value: number) => {
    LogApp(value);
    setRandomKey(`${generateRandomId()}${value}`);
  }, 600);
  const onChangeQuantity = (item: OrderItem, value: number) => {
    setOrder((draft) => {
      let foundIndex = -1;
      if (!item.product_item_id)
        foundIndex = draft.order_items.findIndex((val) => val.product_id === item.product_id);
      else
        foundIndex = draft.order_items.findIndex(
          (val) => val.product_item_id === item.product_item_id,
        );
      if (foundIndex !== -1) {
        if (isNaN(value) && value !== 1) draft.order_items[foundIndex].amount = 1;
        else {
          draft.order_items[foundIndex].amount = value < MAX_ITEM ? value : MAX_ITEM;
        }
      }
    });
    onChangeKey(value);
  };
  const onDeleteOrderItem = (item: OrderItem) => {
    setOrder((draft) => {
      let foundIndex = -1;
      if (!item.product_item_id)
        foundIndex = draft.order_items.findIndex((val) => val.product_id === item.product_id);
      else
        foundIndex = draft.order_items.findIndex(
          (val) => val.product_item_id === item.product_item_id,
        );
      if (foundIndex !== -1) {
        draft.order_items.splice(foundIndex, 1);
      }
    });
  };
  const getProducts = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const res: ProductRoot = await productAPI.getProductsV1({ ...payload });
      setProductData((draft) => {
        if (payload.page === 0) {
          draft.amount = res.data.amount;
          draft.products = res.data.products.map((item) => {
            return {
              ...item,
              selectedClassification: DEFAULT_CLASSIFICATION_PARAMS,
              selectedSubClassification: DEFAULT_CLASSIFICATION_PARAMS,
              amount: 0,
              isSelected: false,
              quantity: onGetRemainProduct(item),
            };
          });
        } else {
          draft.products = draft.products.concat(res.data.products);
        }
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const onSelectClassification = (item: Product, classification: Classification) => {
    setProductData((draft) => {
      const foundIndex = draft.products.findIndex((val) => item.id === val.id);
      if (foundIndex !== -1) draft.products[foundIndex].selectedClassification = classification;
    });
  };
  const onSelectSubClassification = (item: Product, classification: SubClassification) => {
    setProductData((draft) => {
      const foundIndex = draft.products.findIndex((val) => item.id === val.id);
      if (foundIndex !== -1) draft.products[foundIndex].selectedSubClassification = classification;
    });
  };
  const onModalInteract = (value: boolean) => {
    setOpened(value);
    if (!value) setPayload({ ...payload, page: 0 });
  };
  const onClose = () => {
    onModalInteract(false);
    setProductData((draft) => {
      draft.products.forEach((item) => {
        if (item.selectedClassification?.id !== '')
          item.selectedClassification = DEFAULT_CLASSIFICATION_PARAMS;
        if (item.selectedSubClassification?.id !== '')
          item.selectedSubClassification = DEFAULT_CLASSIFICATION_PARAMS;
        if (item.amount) item.amount = 0;
      });
    });
  };
  const getTooltipMessage = (item: Product) => {
    const { classifications, selectedClassification, selectedSubClassification } = item;
    const attributeName = classifications?.[0]?.attribute_name?.toLowerCase();
    const subAttributeName =
      selectedClassification?.sub_classifications?.[0]?.attribute_name?.toLowerCase();
    if (classifications.length) {
      if (selectedClassification?.id === '') return `Vui lòng chọn ${attributeName}`;
      if (selectedClassification?.sub_classifications.length)
        if (selectedSubClassification?.id === '') return `Vui lòng chọn ${subAttributeName}`;
    }
    return `Vui lòng chọn số lượng`;
  };
  const disableCheckbox = (item: Product) => {
    const { classifications, selectedClassification, selectedSubClassification, amount } = item;
    if (amount === 0) return true;
    if (classifications.length) {
      if (selectedClassification?.id === '') return true;
      if (selectedClassification?.sub_classifications.length)
        if (selectedSubClassification?.id === '') return true;
    }
    return false;
  };
  const onIncreaseProducts = (item: Product) => {
    setProductData((draft) => {
      const foundIndex = draft.products.findIndex((val) => item.id === val.id);
      if (foundIndex !== -1) {
        draft.products[foundIndex].amount =
          (draft.products[foundIndex]?.amount || 0) < MAX_ITEM
            ? (draft.products[foundIndex]?.amount || 0) + 1
            : MAX_ITEM;
      }
    });
  };
  const onDecreaseProducts = (item: Product) => {
    setProductData((draft) => {
      const foundIndex = draft.products.findIndex((val) => item.id === val.id);
      if (
        foundIndex !== -1 &&
        draft.products[foundIndex].quantity !== 0 &&
        draft.products[foundIndex].amount !== 0
      )
        draft.products[foundIndex].amount = (draft.products[foundIndex]?.amount || 0) - 1;
    });
  };
  const onChangeQuantityProducts = (item: Product, value: number) => {
    setProductData((draft) => {
      const foundIndex = draft.products.findIndex((val) => item.id === val.id);
      if (foundIndex !== -1)
        draft.products[foundIndex].amount = value < MAX_ITEM ? value : MAX_ITEM;
    });
  };
  const onSelectProduct = (item: Product) => {
    setProductData((draft) => {
      const foundIndex = draft.products.findIndex((val) => item.id === val.id);
      if (foundIndex !== -1) {
        draft.products[foundIndex].isSelected = !draft.products[foundIndex].isSelected;
      }
    });
  };
  const onGetRemainProduct = (item: Product) => {
    if (item.classifications.length > 0) return getRemainProduct(item.classifications);
    return item?.quantity - item.solded_quantity;
  };
  const getRemainProductFromClassification = (item: Product) => {
    if (item.classifications.length) {
      if (item.selectedSubClassification?.id)
        return item.selectedSubClassification.product_item.quantity;
      if (item.selectedClassification?.id)
        return item.selectedClassification.product_item?.quantity;
    }
    return item.quantity;
  };
  const onConfirm = async () => {
    try {
      dispatch(setLoading(true));
      const orderItems: OrderItem[] = productData.products
        .filter((item) => item.isSelected)
        .map((item) => {
          const defaultValues = {
            name: item.name,
            amount: item?.amount || 0,
            warehouse: item.warehouse,
            image: item.images?.[0],
            promotions: item.promotions,
            product_amount_remain: getRemainProductFromClassification(item),
            deduct_percent: item.deduct_percent,
            reward: item.reward,
          };
          if (item.classifications.length) {
            if (item.selectedSubClassification?.id) {
              const { selectedSubClassification, selectedClassification } = item;
              return {
                product_id: item.id,
                product_item_id: selectedSubClassification.product_item.id,
                price: selectedSubClassification.product_item.selling_price,
                classification_name: selectedClassification?.value,
                classification_id: selectedClassification?.id,
                sub_classification_name: selectedSubClassification.value,
                sub_classification_id: selectedSubClassification.id,
                ...defaultValues,
              } as OrderItem;
            } else if (item.selectedClassification?.id) {
              const { selectedClassification } = item;
              return {
                product_id: item.id,
                product_item_id: selectedClassification?.product_item?.id,
                price: selectedClassification?.product_item?.selling_price,
                classification_name: selectedClassification?.value,
                classification_id: selectedClassification?.id,
                sub_classification_name: null,
                sub_classification_id: null,
                ...defaultValues,
              } as OrderItem;
            }
          }
          return {
            product_id: item.id,
            price: item.selling_price,
            classification_name: null,
            classification_id: null,
            sub_classification_name: null,
            sub_classification_id: null,
            product_item_id: null,
            ...defaultValues,
          } as OrderItem;
        });
      const payload: DraftOrderItem[] = [...order.order_items, ...orderItems].map((item) => {
        return {
          product_id: item.product_id as string,
          product_item_id: item.product_item_id as string,
          promotion_id: item.promotions?.at(0)?.id as string,
          quantity: item.amount,
          note: item.note as string,
        };
      });
      await getOrderItemDraftV2(payload);
      onModalInteract(false);
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const payload: UpdateProductOrderPayload = {
        customer_id: order.customer.id,
        note: getValues('note'),
        order_items: order.order_items.map((item) => {
          return {
            product_id: item.product_id,
            product_item_id: item.product_item_id,
            quantity: item.amount,
            price: item.price,
            reward: item.reward,
            deduct_percent: item.deduct_percent,
            note: item.note,
          };
        }),
      };
      const res = await orderAPI.updateOrder(id || '', payload);
      navigate(-1);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const loadMoreData = () => {
    setPayload({ ...payload, page: (payload.page ?? 0) + 1 });
  };
  const onSearch = debounce((value: string) => {
    setPayload({ ...payload, keyword: value, page: 0 });
  }, 600);
  const onChangeWarehouse = (id: string) => {
    if (id === 'null') {
      const clonedPayload = { ...payload };
      delete clonedPayload.warehouse_id;
      setPayload(clonedPayload);
    } else setPayload({ ...payload, warehouse_id: id, page: 0 });
  };
  const onChangeCategory = (id: string) => {
    if (id === 'null') {
      const clonedPayload = { ...payload };
      delete clonedPayload.category_id;
      setPayload(clonedPayload);
    } else setPayload({ ...payload, category_id: id, page: 0 });
  };
  const getDropdownData = async () => {
    try {
      const warehouses: WarehouseRoot = await warehouseAPI.getWarehouses({ all: true });
      const categories: CategoryListRoot = await categoriesAPI.getCategories({ all: true });
      setDropdownData((draft) => {
        draft.warehouses = warehouses.data.warehouse_response.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
        draft.warehouses = [
          { label: 'Tất cả', value: 'null', item: DEFAULT_WAREHOUSE_FILTER_ITEM },
          ...draft.warehouses,
        ];
        draft.categories = categories.data.categories.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
        draft.categories = [
          { label: 'Tất cả', value: 'null', item: DEFAULT_CATEGORY_FILTER_ITEM },
          ...draft.categories,
        ];
      });
    } catch (error) {
      LogApp(error, 'err');
    }
  };
  const openProductModal = () => {
    setPayload(DEFAULT_API_PARAMS);
    onModalInteract(true);
  };
  const validMoney = () => {
    const addOn = getValues('money_addon');
    if (addOn) return convertPriceToFloat(addOn);
    return 0;
  };
  const updateAddon = async () => {
    try {
      dispatch(setLoading(true));
      const res = await orderAPI.updateAddOn(id || '', validMoney());
      await getOrder();
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const goBack = () => {
    navigate(-1);
  };
  const getRemainProduct = (classifications: Classification[]) => {
    let res: number[] = [];
    const productItems = classifications
      .map((item) => item.product_item)
      .filter((item) => item !== undefined);
    if (productItems.length) res = productItems.map((item) => (item ? item.quantity : 0));
    else {
      res = classifications.flatMap((classification) =>
        classification.sub_classifications.map(
          (subClassification) => subClassification.product_item.quantity,
        ),
      );
    }
    return res.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };
  const syncOrder = async () => {
    try {
      dispatch(setLoading(true));
      const res = await orderAPI.syncOrder(id || '');
      if (res?.status !== 200) {
        toast.error(res.message, {
          position: 'top-right',
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: 'colored',
        });
        toast.error;
      } else {
        toast.success('Thành công', {
          position: 'top-right',
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: 'light',
        });
        await getOrder();
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    getOrder();
    getDropdownData();
  }, []);
  useEffect(() => {
    getProducts();
  }, [payload.page, payload.keyword, payload.category_id, payload.warehouse_id]);
  useEffect(() => {
    if (randomKey) getOrderItemDraft();
  }, [randomKey]);
  LogApp(payload, 'ord');
  return (
    <OrderDetailSection
      loadMoreData={loadMoreData}
      register={register}
      order={order}
      control={control}
      watch={watch}
      setValue={setValue}
      getValues={getValues}
      onDecrease={onDecrease}
      onIncrease={onIncrease}
      onChangeQuantity={onChangeQuantity}
      onModalInteract={onModalInteract}
      opened={opened}
      productData={productData}
      onSelectClassification={onSelectClassification}
      onSelectSubClassification={onSelectSubClassification}
      onClose={onClose}
      getTooltipMessage={getTooltipMessage}
      disableCheckbox={disableCheckbox}
      onIncreaseProducts={onIncreaseProducts}
      onDecreaseProducts={onDecreaseProducts}
      onChangeQuantityProducts={onChangeQuantityProducts}
      onSelectProduct={onSelectProduct}
      onConfirm={onConfirm}
      onSubmit={onSubmit}
      productFilterData={dropdownData}
      onSearch={onSearch}
      onChangeWarehouse={onChangeWarehouse}
      onChangeCategory={onChangeCategory}
      payload={payload}
      openProductModal={openProductModal}
      onDeleteOrderItem={onDeleteOrderItem}
      goBack={goBack}
      updateAddon={updateAddon}
      syncOrder={syncOrder}
      onChangeNote={onChangeNote}
      onViewDetail={onViewDetail}
    />
  );
};
