import '@translations/i18n';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { ModuleMain } from 'src/modules';
import { persistor, store } from 'src/redux';
import { ALL_THEMES } from './configs/constants';
import { GlobalStyles, themes, useTheme } from './theme';
import { setToLS } from './utils/storage';
import ErrorBoundary from './ErrorBoundary';
import { ErrorPage } from './ErrorPage';
// ..
AOS.init();
function App() {
  const { theme, themeLoaded } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  useEffect(() => {
    setSelectedTheme(theme);
  }, [themeLoaded]);

  useLayoutEffect(() => {
    setToLS(ALL_THEMES, themes);
    // WebFont.load({
    //   google: {
    //     families: getFonts(),
    //   },
    // });
  }, []);

  return (
    <Provider store={store}>
      {themeLoaded && selectedTheme && (
        <ThemeProvider theme={selectedTheme || themes.theme.light}>
          <GlobalStyles />
          <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary fallback={<ErrorPage error="" />}>
              <ModuleMain setTheme={setSelectedTheme} />
            </ErrorBoundary>
          </PersistGate>
        </ThemeProvider>
      )}
    </Provider>
  );
}

export default App;
