import { MenuProps, Tooltip } from 'antd';
import { memo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavMenuIcon, Sidebar } from '@components';
import {
  ADMIN_SIDEBAR_DATA,
  DEFAULT_SIDEBAR_DATA,
  INITIAL_PAGE,
  MAT_SM_SCREEN_WIDTH,
  PATH_BANNERS,
  PATH_CATEGORIES,
  PATH_CUSTOMERS,
  PATH_DASHBOARD,
  PATH_ERRORS,
  PATH_ORDER,
  PATH_PRODUCT,
  PATH_PROMOTION,
  PATH_SELLER,
  PATH_WAREHOUSE,
  ROLES,
  enumMainNavKey,
  enumNavKey,
} from '@configs';
import {
  selectApp,
  selectAuth,
  selectLayout,
  setCurrentPage,
  setNavCurrentIsOpenKey,
  setNavCurrentKey,
  setSidebarCollapsed,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp, useForceUpdate, useMediaQuery } from '@utils';

export const SidebarModule = memo(() => {
  const { themeMode } = useAppSelector(selectApp);
  const { sidebarCollapsed, navCurrentKey } = useAppSelector(selectLayout);
  const { role } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const forceUpdate = useForceUpdate();

  const isSMScreen = useMediaQuery(`(max-width:${MAT_SM_SCREEN_WIDTH})`);
  const authState = useAppSelector(selectAuth);

  const handleChangeCollapsed = (value: boolean) => {
    dispatch(setSidebarCollapsed(value));
  };

  const handleSelectMenuItem: MenuProps['onClick'] = (e) => {
    const currentKey = Number(e.key);
    dispatch(setCurrentPage(0));
    switch (currentKey) {
      case enumNavKey.DASHBOARD:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.DASHBOARD));
        navigate(PATH_DASHBOARD);
        break;
      case enumNavKey.CATEGORIES:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.CATEGORIES));
        dispatch(setCurrentPage(INITIAL_PAGE));
        navigate(PATH_CATEGORIES);
        break;
      case enumNavKey.PRODUCT:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.MARKETING));
        navigate(PATH_PRODUCT);
        break;
      case enumNavKey.WAREHOUSE:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.WAREHOUSE));
        navigate(PATH_WAREHOUSE);
        break;
      case enumNavKey.ORDER:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.ORDER));
        navigate(PATH_ORDER);
        break;
      case enumNavKey.SELLERS:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.SELLERS));
        navigate(PATH_SELLER);
        break;
      case enumNavKey.CUSTOMERS:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.CUSTOMERS));
        navigate(PATH_CUSTOMERS);
        break;
      case enumNavKey.PROMOTION:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.PROMOTION));
        navigate(PATH_PROMOTION);
        break;
      case enumNavKey.BANNERS:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.BANNERS));
        navigate(PATH_BANNERS);
        break;
      case enumNavKey.ERRORS:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.ERRORS));
        navigate(PATH_ERRORS);
        break;
      default:
        return;
    }
    // dispatch(setNavCurrentKey(currentKey));
  };
  const getAppSidebarByRole = () => {
    if (authState?.username === 'luatnq' || authState?.username === 'hieund')
      return [
        ...ADMIN_SIDEBAR_DATA,
        {
          label: 'Error Monitor',
          path: PATH_ERRORS,
          pathKey: enumNavKey.ERRORS,
        },
      ];
    switch (role) {
      case ROLES.ADMIN:
        return ADMIN_SIDEBAR_DATA;
      default:
        return DEFAULT_SIDEBAR_DATA;
    }
  };

  const navbarItems: MenuProps['items'] = getAppSidebarByRole().map((item, _) => {
    return {
      key: item.pathKey,
      icon: (
        <Tooltip title={item?.label}>
          <NavMenuIcon pathKey={item.pathKey} className="menu__icon" />
        </Tooltip>
      ),
      label: item?.label,
    };
  });
  useEffect(() => {
    switch (true) {
      case pathname?.includes(PATH_DASHBOARD):
        dispatch(setNavCurrentKey(enumNavKey.DASHBOARD));
        break;
      case pathname?.includes(PATH_CATEGORIES):
        dispatch(setNavCurrentKey(enumNavKey.CATEGORIES));
        break;
      case pathname?.includes(PATH_PRODUCT):
        dispatch(setNavCurrentKey(enumNavKey.PRODUCT));
        break;
      case pathname?.includes(PATH_SELLER):
        dispatch(setNavCurrentKey(enumNavKey.SELLERS));
        break;
      case pathname?.includes(PATH_WAREHOUSE):
        dispatch(setNavCurrentKey(enumNavKey.WAREHOUSE));
        break;
      case pathname?.includes(PATH_ORDER):
        dispatch(setNavCurrentKey(enumNavKey.ORDER));
        break;
      case pathname?.includes(PATH_CUSTOMERS):
        dispatch(setNavCurrentKey(enumNavKey.CUSTOMERS));
        break;
      case pathname?.includes(PATH_PROMOTION):
        dispatch(setNavCurrentKey(enumNavKey.PROMOTION));
        break;
      case pathname?.includes(PATH_BANNERS):
        dispatch(setNavCurrentKey(enumNavKey.BANNERS));
        break;
      case pathname?.includes(PATH_ERRORS):
        dispatch(setNavCurrentKey(enumNavKey.ERRORS));
        break;
      default:
        dispatch(setNavCurrentKey(undefined));
        dispatch(setNavCurrentIsOpenKey(undefined));
        return;
    }
  }, [pathname]);

  return (
    <Sidebar
      isSMScreen={isSMScreen}
      collapsed={sidebarCollapsed}
      onChangeCollapsed={handleChangeCollapsed}
      themeMode={themeMode}
      navbarItems={navbarItems}
      onSelectMenuItem={handleSelectMenuItem}
    />
  );
});
