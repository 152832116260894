import {
  enumNavKey,
  enumRuleType,
  enumCashbackType,
  enumStatus,
  enumGender,
  enumPageSize,
  enumMemberType,
  enumDashboardFilterTime,
} from './enum';
import {
  PATH_DASHBOARD,
  PATH_CATEGORIES,
  PATH_WAREHOUSE,
  PATH_PRODUCT,
  PATH_ORDER,
  PATH_CUSTOMERS,
  PATH_SELLER,
  PATH_BANNERS,
  PATH_ERRORS,
} from './../routes/navigation';

export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE || 'en';
const ALL_THEMES = 'themes';
const CURRENT_THEME = 'theme';

const SIDEBAR_WIDTH = '260px';
const SIDEBAR_COLLAPSED_WIDTH = '105px';
const SIDEBAR_ICON_SIZE = '24px';
const HEADER_HEIGHT = '65px';
const HEADER_PADDING_TOP = '20px';
const DEFAULT_SIDEBAR_DATA = [
  {
    label: 'Trang chủ',
    path: PATH_DASHBOARD,
    pathKey: enumNavKey.DASHBOARD,
  },
  {
    label: 'Quản lý danh mục',
    path: PATH_CATEGORIES,
    pathKey: enumNavKey.CATEGORIES,
    subItems: [],
  },
  {
    label: 'Quản lý kho',
    path: PATH_WAREHOUSE,
    pathKey: enumNavKey.WAREHOUSE,
    subItems: [],
  },
  {
    label: 'Quản lý sản phẩm',
    path: PATH_PRODUCT,
    pathKey: enumNavKey.PRODUCT,
  },
  {
    label: 'Quản lý đơn hàng',
    path: PATH_ORDER,
    pathKey: enumNavKey.ORDER,
  },
  {
    label: 'Quản lý khách hàng',
    path: PATH_CUSTOMERS,
    pathKey: enumNavKey.CUSTOMERS,
  },
];
const PROMOTION_TYPE = {
  OBJECT: 0,
  MONEY: 1,
};
const ADMIN_SIDEBAR_DATA = [
  ...DEFAULT_SIDEBAR_DATA,
  {
    label: 'Quản lý nhân viên',
    path: PATH_SELLER,
    pathKey: enumNavKey.SELLERS,
  },
  {
    label: 'Quản lý khuyến mãi',
    path: PATH_CUSTOMERS,
    pathKey: enumNavKey.PROMOTION,
  },
  {
    label: 'Quản lý banners',
    path: PATH_BANNERS,
    pathKey: enumNavKey.BANNERS,
  },
];

const AUTH_THEME_COLOR = '#184f64';

const MAIN_THEME_DATA = {
  mainColor: '#184f64',
};

const MAT_SM_SCREEN_WIDTH = '1279px';
const MAT_SM_SCREEN_WIDTH_MIN = '1280px';
const MAX_ITEM_PER_PAGE = 10;
const RESET = 'RESET';

const CASHBACK_RULE_TYPE = [
  {
    value: enumRuleType.standard,
    label: 'Standard Cashback Rule',
  },
  {
    value: enumRuleType.superior,
    label: 'Superior Cashback Rule',
  },
];

const CASHBACK_TYPE = [
  {
    value: enumCashbackType.cost,
    label: '$',
  },
  {
    value: enumCashbackType.percent,
    label: '%',
  },
];

const STATUS_TYPE = [
  {
    value: enumStatus.ACTIVE,
    label: 'Active',
  },
  {
    value: enumStatus.INACTIVE,
    label: 'Inactive',
  },
  {
    value: enumStatus.NONE,
    label: 'All',
  },
];

const GENDER_TYPE = [
  {
    value: enumGender.MALE,
    label: 'Male',
  },
  {
    value: enumGender.FEMALE,
    label: 'Female',
  },
];
export const TOP_FILTER = [
  {
    value: 10,
    label: 'Top 10',
  },
  {
    value: 20,
    label: 'Top 20',
  },
  {
    value: 50,
    label: 'Top 50',
  },
];

const PAGE_SIZE_OPTIONS = [
  {
    value: enumPageSize.LIMIT_10,
    label: '10',
  },
  {
    value: enumPageSize.LIMIT_20,
    label: '20',
  },
  {
    value: enumPageSize.LIMIT_50,
    label: '50',
  },
];

const FILTER_MEMBER_TYPE = [
  {
    value: enumMemberType.ACTIVE,
    label: 'Active members',
  },
  {
    value: enumMemberType.NEW_MEMBER,
    label: 'New members',
  },
  {
    value: enumMemberType.ALL,
    label: 'All',
  },
];

const DASHBOARD_FILTER_TIME = [
  {
    value: enumDashboardFilterTime.ALL_DAYS,
    label: 'All days',
  },
  {
    value: enumDashboardFilterTime.TODAY,
    label: 'Today',
  },
  {
    value: enumDashboardFilterTime.YESTERDAY,
    label: 'Yesterday',
  },
  {
    value: enumDashboardFilterTime.LAST_7_DAYS,
    label: 'Last 7 days',
  },
  {
    value: enumDashboardFilterTime.LAST_14_DAYS,
    label: 'Last 14 days',
  },
  {
    value: enumDashboardFilterTime.LAST_21_DAYS,
    label: 'Last 21 days',
  },
  {
    value: enumDashboardFilterTime.LAST_28_DAYS,
    label: 'Last 28 days',
  },
  {
    value: enumDashboardFilterTime.LAST_60_DAYS,
    label: 'Last 60 days',
  },
  {
    value: enumDashboardFilterTime.CUSTOM,
    label: 'Custom date',
  },
];

const OPENING_TYPE = {
  ALL: 1,
  CUSTOM: 2,
};
const DAYS = {
  MON: 'monday',
  TUE: 'tuesday',
  WED: 'wednesday',
  THU: 'thursday',
  FRI: 'friday',
  SAT: 'saturday',
  SUN: 'sunday',
};
const DEFAULT_OPENING_HOUR = {
  [DAYS.MON]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.TUE]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.WED]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.THU]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.FRI]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.SAT]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: false,
  },
  [DAYS.SUN]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: false,
  },
};
export const DEFAULT_WELCOMED_BENEFITS_VALUE = {
  CASHBACK: 'cashback',
  POINTS: 'point',
  COUPONS: 'coupon',
  FREE_ITEMS: 'item',
};
const DEFAULT_WELCOMED_BENEFITS = [
  { value: DEFAULT_WELCOMED_BENEFITS_VALUE.CASHBACK, label: 'Cashback', disabled: false },
  { value: DEFAULT_WELCOMED_BENEFITS_VALUE.POINTS, label: 'Points', disabled: false },
  { value: DEFAULT_WELCOMED_BENEFITS_VALUE.COUPONS, label: 'Coupons', disabled: false },
  { value: DEFAULT_WELCOMED_BENEFITS_VALUE.FREE_ITEMS, label: 'Free items', disabled: false },
];
export const ruleDateFormat = 'DD-MM-YYYY';
export const ruleTimeFormat = 'HH:mm:ss';
export const dateOfBirthFormat = 'DD/MM/YYYY';
export const dateTimeFormat = 'DD-MM-YYYY HH:mm:ss';
export const dateFormat = 'DD-MM-YYYY';

export const RESEND_OTP_COUNTDOWN_TIME = 60; //seconds
export const MEMBER_LINK = 'https://minty-member-stage.adamo.tech/';
export const INITIAL_PAGE = 0;
export const DEFAULT_ANNOUNCEMENT_TYPE = 'redirect';
export const DEFAULT_ANNOUNCEMENT_STATUS = true;
export const MAXIMUM_IMAGE_SIZE = 2000000; //bytes - 1 MB
export const MAXIMUM_LIMIT = 1000000; //bytes - 1 MB

export const MAP_BOX_DEV_KEY =
  'pk.eyJ1IjoiYW5keWhpdSIsImEiOiJjbGNyOWl2ZDAwYmdjM3FucGMwbGhkbW90In0.ezl2EXwziuTkjZmYeh4Bcw';
const NOT_UPDATE = 'Chưa cập nhật';
const ORDER_STATUS_NUMBER = {
  ALL: null,
  WAITING_FOR_ACCEPT: 0,
  WAITING_FOR_APPROVE: 1,
  DELIVERING: 3,
  RECEIVED: 4,
  APPROVED: 2,
  CANCEL: -1,
};
const ORDER_STATUS_TEXT = {
  [ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT]: 'Chờ xác nhận',
  [ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE]: 'Chờ phê duyệt',
  [ORDER_STATUS_NUMBER.APPROVED]: 'Đang xuất kho',
  [ORDER_STATUS_NUMBER.DELIVERING]: 'Đang giao hàng',
  [ORDER_STATUS_NUMBER.RECEIVED]: 'Đã hoàn thành',
  [ORDER_STATUS_NUMBER.CANCEL]: 'Đã huỷ',
};
export const ORDER_STATUSES = [
  {
    value: 'null',
    label: 'Tất cả',
  },
  {
    value: ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT.toString(),
    label: ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT],
  },
  {
    value: ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE.toString(),
    label: ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE],
  },
  {
    value: ORDER_STATUS_NUMBER.APPROVED.toString(),
    label: ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.APPROVED],
  },
  {
    value: ORDER_STATUS_NUMBER.DELIVERING.toString(),
    label: ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.DELIVERING],
  },
  {
    value: ORDER_STATUS_NUMBER.RECEIVED.toString(),
    label: ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.RECEIVED],
  },
  {
    value: ORDER_STATUS_NUMBER.CANCEL.toString(),
    label: ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.CANCEL],
  },
];
const SELLER_ORDER_STATUS_OPTIONS = [
  {
    label: ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT],
    value: ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT.toString(),
  },
  {
    label: ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE],
    value: ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE.toString(),
  },
];
const ROLES = {
  ACCOUNTANT: 'ACCOUNTANT_ROLE',
  ADMIN: 'ADMIN_ROLE',
  SELLER: 'SELLER',
};
const ERROR_CODES = {
  UNAUTHORIZED: 401,
};
const SELLER_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
};
export {
  ALL_THEMES,
  MAIN_THEME_DATA,
  CURRENT_THEME,
  HEADER_HEIGHT,
  SIDEBAR_WIDTH,
  ADMIN_SIDEBAR_DATA,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_ICON_SIZE,
  AUTH_THEME_COLOR,
  HEADER_PADDING_TOP,
  MAT_SM_SCREEN_WIDTH,
  MAT_SM_SCREEN_WIDTH_MIN,
  RESET,
  CASHBACK_TYPE,
  CASHBACK_RULE_TYPE,
  STATUS_TYPE,
  GENDER_TYPE,
  PAGE_SIZE_OPTIONS,
  FILTER_MEMBER_TYPE,
  OPENING_TYPE,
  DEFAULT_OPENING_HOUR,
  DAYS,
  DEFAULT_WELCOMED_BENEFITS,
  DASHBOARD_FILTER_TIME,
  NOT_UPDATE,
  MAX_ITEM_PER_PAGE,
  ORDER_STATUS_TEXT,
  SELLER_ORDER_STATUS_OPTIONS,
  ORDER_STATUS_NUMBER,
  ROLES,
  DEFAULT_SIDEBAR_DATA,
  ERROR_CODES,
  SELLER_STATUS,
  PROMOTION_TYPE,
};
