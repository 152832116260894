import { ErrorContent, IRegisterFields } from '@interfaces';
import { ApiClient, DefaultApiParams } from './axiosClient';

export const monitorAPI = {
  getErrors: (params: DefaultApiParams) => {
    const url = '/log-error';
    return ApiClient.get(url, { params });
  },
  postErrors: (payload: ErrorContent) => {
    const url = '/log-error';
    return ApiClient.post(url, payload);
  },
};
