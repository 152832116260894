import { Col, Collapse, Row } from 'antd';
import moment from 'moment';
import {
  Control,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';

import { PopoverPopup, SharedButton } from '@components';
import { MAIN_THEME_DATA, MAT_SM_SCREEN_WIDTH_MIN } from '@configs';
import {
  AttributeGroupData,
  AttributeItem,
  AttributeParentKey,
  AutocompleteItem,
  Category,
  DropdownProps,
  ProductAttribute,
  ProductAutocompleteData,
  ProductImage,
  ProductInputType,
  Promotion,
  SubCategory,
} from '@interfaces';
import { themes, useTheme } from '@theme';
import { EditorState } from 'draft-js';
import parse from 'html-react-parser';
import { DetailInfo } from './components/DetailInfo';
import { ProductImages } from './components/ProductImages';
import { ProductInfo } from './components/ProductInfo';
import { SaleInfo } from './components/SaleInfo';

const { Panel } = Collapse;
interface IProps {
  isEdit?: boolean;
  errors: Partial<FieldErrorsImpl<any>>;
  watch: UseFormWatch<any>;
  onSubmit?: () => void;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  onCancel?: () => void;
  changeSelectedImage?: (value: any, index: number) => void;
  onChangeTiers?: (value: any) => void;
  tiers: DropdownProps;
  selectedTiers?: number[];
  addAttributeItem: (key: AttributeParentKey) => void;
  removeAttributeItem: (item: AttributeItem, key: AttributeParentKey) => void;
  attrGroupData: AttributeGroupData[];
  removeAttributeGroup: (key: AttributeParentKey, item: AttributeGroupData) => void;
  addAttributeGroup: () => void;
  getChildAttrs: () => AttributeItem[];
  getProductInputs: (
    attrGroupData: AttributeGroupData[],
    type: ProductInputType,
  ) => AttributeItem[];
  attributeList: ProductAttribute[];
  onSelectDropdown: (value: string, key: AttributeParentKey) => void;
  autocompleteData: ProductAutocompleteData;
  onSelectBasicInfo: (value: string, type: 'warehouse' | 'category') => void;
  applyAll: () => void;
  control: Control<any, any>;
  productImages: ProductImage[];
  selectedCategory: AutocompleteItem<Category>;
  onSelectParentCategory: (item: AutocompleteItem<Category>) => void;
  onSelectSubCategory: (item: SubCategory) => void;
  onModalInteract: (value: boolean) => void;
  onCategoryConfirm: () => void;
  onCategoryCancel: () => void;
  selectedSubCategory: SubCategory;
  opened: boolean;
  isOptionEmpty: (searchText: string) => void;
  onAddAttribute: (attr: ProductAttribute) => void;
  onConfirmAddAttribute: () => void;
  attrOpened: boolean;
  onAttrModalInteract: (value: boolean) => void;
  editorRef: any;
  htmlDes: EditorState;
  deleteProduct: () => void;
  deleteImage: (image: ProductImage) => void;
  uploadImageRef: any;
  promotions: Promotion[];
  uploadAttributeImage: (id: string, file: any) => Promise<void>;
  deleteAttributeImage: (id: string) => void;
}

export const ProductForm = (props: IProps) => {
  const {
    errors,
    isEdit,
    getValues,
    onCancel,
    setValue,
    onSubmit,
    register,
    watch,
    selectedTiers,
    // attributeGroup,
    addAttributeItem,
    removeAttributeItem,
    attrGroupData,
    removeAttributeGroup,
    addAttributeGroup,
    getChildAttrs,
    getProductInputs,
    attributeList,
    onSelectDropdown,
    autocompleteData,
    onSelectBasicInfo,
    applyAll,
    control,
    productImages,
    changeSelectedImage,
    selectedCategory,
    onSelectParentCategory,
    onSelectSubCategory,
    selectedSubCategory,
    opened,
    onModalInteract,
    onCategoryConfirm,
    onCategoryCancel,
    isOptionEmpty,
    onAddAttribute,
    onConfirmAddAttribute,
    onAttrModalInteract,
    attrOpened,
    editorRef,
    htmlDes,
    deleteProduct,
    deleteImage,
    uploadImageRef,
    promotions,
    uploadAttributeImage,
    deleteAttributeImage,
  } = props;
  const { theme } = useTheme();
  return (
    <StyledProductSection>
      <form onSubmit={onSubmit}>
        <StyledRow gutter={[20, 16]}>
          <Col className="gutter-row name-type-col name-type-col-left" md={24} lg={24} xl={24}>
            <Collapse defaultActiveKey={['1', '2', '3', '5', '6']} onChange={() => {}}>
              <Panel header="Thông tin cơ bản" key="1">
                <ProductInfo
                  isEdit={isEdit}
                  editorRef={editorRef}
                  getValues={getValues}
                  errors={errors}
                  register={register}
                  autoCompleteData={autocompleteData}
                  onSelectBasicInfo={onSelectBasicInfo}
                  selectedCategory={selectedCategory}
                  onSelectParentCategory={onSelectParentCategory}
                  onSelectSubCategory={onSelectSubCategory}
                  selectedSubCategory={selectedSubCategory}
                  opened={opened}
                  onModalInteract={onModalInteract}
                  onCategoryConfirm={onCategoryConfirm}
                  onCategoryCancel={onCategoryCancel}
                  htmlDes={htmlDes}
                />
              </Panel>
              <Panel header="Thông tin chi tiết" key="2">
                <DetailInfo
                  attrOpened={attrOpened}
                  onAttrModalInteract={onAttrModalInteract}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  autocompleteData={autocompleteData}
                  register={register}
                  attributeList={attributeList}
                  isOptionEmpty={isOptionEmpty}
                  onAddAttribute={onAddAttribute}
                  onConfirmAddAttribute={onConfirmAddAttribute}
                />
              </Panel>
              <Panel header="Thông tin bán hàng" key="3">
                <SaleInfo
                  addAttributeItem={addAttributeItem}
                  removeAttributeItem={removeAttributeItem}
                  attrGroupData={attrGroupData}
                  removeAttributeGroup={removeAttributeGroup}
                  addAttributeGroup={addAttributeGroup}
                  getChildAttrs={getChildAttrs}
                  getProductInputs={getProductInputs}
                  onSelectDropdown={onSelectDropdown}
                  applyAll={applyAll}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  watch={watch}
                  uploadAttributeImage={uploadAttributeImage}
                  deleteAttributeImage={deleteAttributeImage}
                  isEdit={isEdit}
                />
              </Panel>
              {/* <Panel header="Đánh giá" key="4">
                <p>{text}</p>
              </Panel> */}
              <Panel header="Quản lý hình ảnh" key="5">
                <ProductImages
                  uploadImageRef={uploadImageRef}
                  changeSelectedImage={changeSelectedImage}
                  productImages={productImages}
                  deleteImage={deleteImage}
                />
              </Panel>
              {isEdit && (
                <Panel header="Thông tin khuyến mãi" key="6">
                  <div className="promotions">
                    {promotions.map((item, index) => {
                      return (
                        <div className="promotion-item" key={item.id}>
                          <span className="title">Khuyến mãi thứ {index + 1}</span>
                          <div className="promotion-info">
                            <span className="sub-title">
                              Mã khuyến mãi: <span className="content">{item.code}</span>
                            </span>
                            <span className="sub-title">
                              Tiêu đề: <span className="content">{item.name}</span>
                            </span>
                            <span className="sub-title">
                              Thời gian:
                              <span className="content">
                                {moment(item.start_day).format('DD/MM/YYYY')} -{' '}
                                {moment(item.end_day).format('DD/MM/YYYY')}
                              </span>
                            </span>
                            {item.discount_percent > 0 && (
                              <span className="sub-title">
                                Giảm: <span className="content">{item.discount_percent}%</span>
                              </span>
                            )}
                            <span>Chi tiết: {parse(item.description)}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Panel>
              )}
            </Collapse>
          </Col>
        </StyledRow>
        <div className="bottom-content">
          <div className="form__actions">
            <SharedButton
              text="Huỷ"
              className="cancel__btn"
              backgroundColor="transparent"
              textColor={theme?.colors?.button?.text || themes.theme.light.colors.button.text}
              borderColor={theme?.colors?.button?.border || themes.theme.light.colors.button.border}
              btnStyle="basic"
              onClick={onCancel}
            />
            <SharedButton
              onClick={onSubmit}
              // typeHtml="submit"
              text={isEdit ? 'Lưu' : 'Tạo'}
              className="create-rule__button"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="basic"
            />
            {
              <PopoverPopup
                content={
                  <StyledConfirmPopup>
                    <h5 className="text-center items-center mb-2 text-current text-base">
                      Thông báo
                    </h5>
                    <p className="text-sm">Bạn có muốn xoá sản phẩm này không?</p>
                  </StyledConfirmPopup>
                }
                isHaveConfirmButton
                onConfirm={() => {
                  deleteProduct();
                }}
              >
                {isEdit && (
                  <SharedButton
                    text="Xoá"
                    className="create-rule__button btn-delete"
                    backgroundColor={MAIN_THEME_DATA.mainColor}
                    btnStyle="basic"
                  />
                )}
              </PopoverPopup>
            }
          </div>
        </div>
      </form>
    </StyledProductSection>
  );
};
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
  }
`;
const StyledProductSection = styled.div`
  width: 100%;
  .form-section {
    position: relative;
    padding: 3.2rem 0 0;
    border-radius: 0.6rem;
    background: ${(p) => p.theme.colors.bgSection};
  }

  .section-box {
    background: ${(p) => p.theme.colors.bgSection};
    border-radius: 0.6rem;
    padding: 1.6rem 2.2rem;
    box-shadow: 0px 3px 20px #0000000b;
    .box-content {
      padding: 2.6rem;
      .head {
        padding-bottom: 2.3rem;
        .box-title {
          font-size: 1.6rem;
        }
      }
      .main_form {
        display: flex;
        flex-direction: column;
        width: 100%;
        .form-field {
          display: flex;
          flex-direction: column;
          margin-bottom: 2.6rem;
          &:last-child {
            margin-bottom: 0;
          }
          .right-field {
            width: 100%;
          }
          .label {
            margin-right: 2.6rem;
            .label-text {
              font-size: 1.4rem;
              font-weight: 500;
              margin-bottom: 0.6rem;
              color: ${({ theme }: any) => theme?.colors?.text};
            }
            .label-desc {
              font-size: 1.3rem;
              font-weight: 400;
              color: ${({ theme }: any) => theme?.colors?.secondary};
            }
          }
          .value-text {
            font-size: 1.4rem;
            font-weight: 500;
            color: ${({ theme }: any) => theme?.colors?.secondary};
          }
          @media (min-width: 1280px) {
            flex-direction: row;
            align-items: flex-start;
            .label {
              width: 43%;
            }
          }
        }

        .cannot-changed-field {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .shared-input {
          margin-bottom: 0.8rem;
        }
        .inner-input,
        .ant-picker,
        input,
        .ant-select-selector {
          @media (min-width: 768px) {
            height: 4rem;
          }
        }
      }
    }
  }
  .edit_form {
    padding: 0 0 1.2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    .shared-input,
    .ant-select {
      margin-bottom: 2rem;
    }

    @media (min-width: 768px) {
      .name-type-col-left {
        padding-right: 5rem;
      }
      .mob-row {
        margin-bottom: 3.2rem;
      }
    }

    @media (min-width: 1200px) {
      .name-type-col {
        max-width: calc((100% * 2 / 3 - 11.2rem) / 2);
      }
      .name-type-col-left {
        padding-right: 0;
        margin-right: 4.2rem;
      }
      .spent-get-col {
        padding-right: 1.5rem;
      }
      .duration-col {
        padding-left: 1.5rem;
      }
    }
  }

  .right-box {
    background: ${(p) => p.theme.colors.bgSection};
    border-radius: 0.6rem;
    padding: 2rem 2.2rem;
    box-shadow: 0px 3px 20px #0000000b;

    .label {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
      font-size: 1.6rem;
      font-weight: 500;
    }

    .upload__image {
      width: 100%;
      height: auto;
    }
  }
  .bottom-content {
    width: 100%;
    display: flex;
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
    .form__actions {
      display: flex;
      width: 100%;
      .btn {
        width: calc((100% - 2rem) / 2);
        margin-right: 2rem;
      }
      @media (min-width: 1280px) {
        display: flex;
        width: 100%;
        align-items: center;
        max-width: 23rem;
      }

      @media (max-width: 820px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0;
        .btn {
          width: 100%;
          &:first-child {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
  .promotions {
    span {
      display: inline-block;
    }
    .promotion-item {
      .promotion-info {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
      }
      .title {
        font-size: 1.7rem;
        font-weight: bold;
      }
      .sub-title {
        font-size: 1.4rem;
        .content {
          font-size: 1.4rem;
          font-weight: bold;
        }
      }
    }
  }
`;

const StyledRow = styled((props: any) => <Row {...props} />)`
  margin-bottom: 1.4rem;
  @media (min-width: ${MAT_SM_SCREEN_WIDTH_MIN}) {
    margin-bottom: 4.2rem;
    &:first-child {
      margin-bottom: 5.6rem;
    }
  }
  width: 100%;
`;

const StyledSubRow = styled((props: any) => <Row {...props} />)`
  align-items: center;
  margin-bottom: 2rem;
`;
