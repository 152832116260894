import { DefaultApiParams, monitorAPI } from '@api';
import { CategoryData, ErrorTable } from '@components';
import { MAX_ITEM_PER_PAGE } from '@configs';
import { ErrorColumn, ErrorData, Errors, RootResponse } from '@interfaces';
import { selectApp, setCurrentPage, setTableLoading, useAppDispatch, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { ColumnsType } from 'antd/lib/table';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

export const ErrorModule = () => {
  const dispatch = useAppDispatch();
  const { themeMode, currentPage } = useAppSelector(selectApp);

  const navigate = useNavigate();
  const [errors, setErrors] = useImmer<ErrorData>({
    limit: 10,
    count: 1,
    data: [],
  });
  const [payload, setPayload] = useState<DefaultApiParams>({
    page: currentPage || 0,
    size: 10,
    keyword: '',
  });
  const columns: ColumnsType<any> = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'no',
        key: 'no',
        render: (text, record, index) => index + currentPage * Number(payload?.size) + 1,
        width: '10%',
      },
      { title: 'User', dataIndex: 'user', key: 'user' },
      { title: 'Detail', dataIndex: 'detail', key: 'detail' },
      { title: 'Current url', dataIndex: 'url', key: 'url' },
      { title: 'Http status', dataIndex: 'status', key: 'status' },
      { title: 'Created at', dataIndex: 'created_at', key: 'created_at' },
    ];
  }, [currentPage]);

  const onSearch = debounce((value: string) => {
    setPayload({ ...payload, keyword: value === '' ? null : value.trim(), page: 0 });
  }, 500);
  const onPageChange = (page: number) => {
    dispatch(setCurrentPage(Number(page)));
    setPayload({ ...payload, page });
  };

  const handleChangePageSize = (size: number) => {
    setPayload({ ...payload, size });
  };
  const getErrors = useCallback(async () => {
    try {
      dispatch(setTableLoading(true));
      const res: RootResponse<Errors> = await monitorAPI.getErrors({
        ...payload,
      });
      const newResponse: ErrorColumn[] = res.data.content.map((item, index) => {
        return {
          key: index,
          no: index + 1,
          user: item.username,
          detail: item.detail,
          url: item.current_url,
          status: item.http_status,
          created_at: moment(item.created_at).format('DD/MM/YYYY'),
        };
      });
      LogApp(newResponse, 'new');
      setErrors({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.amount,
        data: newResponse,
      });
    } catch (error) {
    } finally {
      dispatch(setTableLoading(false));
    }
  }, [payload.keyword, payload.page, payload.size]);
  useEffect(() => {
    getErrors();
  }, [payload.keyword, payload.page, payload.size]);
  return (
    <>
      <ErrorTable
        onSearch={onSearch}
        columns={columns}
        errors={errors}
        currentPage={payload.page}
        onPageChange={onPageChange}
      />
    </>
  );
};
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
const StyledEditBtn = styled.div`
  display: flex;
  justify-content: center;
  .edit-btn {
    padding: 0.8rem 1.8rem;
    width: fit-content;
    align-self: center;
  }
`;
const StyledTableWrapper = styled.div`
  .ant-table-cell {
    padding: 2rem;
  }
  .ant-table table {
    border-radius: 8px 8px 0 0;
  }
  .ant-table-content {
    padding: 2rem;
    background-color: #fbfbfb;
  }
`;
